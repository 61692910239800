@import "_default.scss_include-mixins";

.page.content--home {

	.hero {
		position:relative;
		float:left;
		width:100vw;
		height:100vh;
		min-height:400px;

		.readmore {
			position:absolute;
			bottom:60px;
			left:50%;
			margin-left:-40px;
			width:80px;
			text-align:center;

			.label {
				font-size:15px;
				color:#000;
			}

			.icon {
				margin-top:10px;
				transform:rotate(90deg);
			}
		}
		
		.logo {
			position:absolute;
			left:50%;
			top:50%;
			transform:translate(-50%,-50%);
			width:50vw;
			height:50vw;

			@include sm {
				width:100vw;
				height:100vw;
				transform:translate(-50%,-60%);
			}

			.helper-video {
				display:inline-block;
			    position: relative;
			    width: 100%;
			    height: 0;
			    padding-bottom: 100%;

			    iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}

			}

		}

	}

	.rest {
		float:left;
		width:100%;
		padding-bottom:10vw;

		/*
		Videoplayer
		*/
		.videoplayer {
			position:relative;
			border-radius:15px;
			overflow:hidden;
			padding-bottom:100%;

			&:hover .overlay {
				background-color:rgba(0,0,0,0.5);
			}

				&:hover .play-button .circle {
					transform:scale(1.1);
				}

			.overlay {
				position:absolute;
				left:0;
				top:0;
				width:100%;
				height:100%;
				background-color:rgba(0,0,0,0.7);
				transition:all 0.2s;
			}

			.play-button {
				position:absolute;
				left:50%;
				top:50%;
				transform:translate(-50%,-50%);
				cursor:pointer;
				text-align:center;

				.circle {
					display:inline-block;
					position:relative;
					width:120px;
					height:120px;
					border-radius:50%;
					background-color:#fff;
					transition:all 0.2s;

					@include md {
						width:60px;
						height:60px;
					}

					svg {
						position:absolute;
						left:50%;
						top:50%;
						transform:translate(-50%,-50%);
						width:50px;
						height:50px;
						fill:#000;

						@include md {
							width:20px;
							height:20px;
						}

					}

				}

				.text {
					float:left;
					width:100%;
					margin-top:20px;
					font-size:40px;
					font-weight:bold;

					@include md {
						font-size:20px;
					}

				}

			}

		}
	}

}