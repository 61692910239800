@import "_default.scss_include-mixins";	 

header {

	position:fixed;
	top:0;
	left:0;
	width:100%;
	padding-top:1vw;
	padding-bottom:1vw;
	text-align:center;
	z-index:1000;

	.inside {
		display:inline-block;
		width:90%;

		.logo {
			float:left;
			background-color:#fff;
			border-radius:50%;
			width:4.3vw;
			height:4.3vw;
			overflow:hidden;

			svg {
				width:4.3vw;
				height:4.3vw;

				.st0{ 
					fill:#F93500; 
				}
			}
		}

		nav {
			float:right;
			margin-top:0.6vw;

			ul {
				list-style-type: none;

				li {
					float:left;
					margin-left:20px;
					margin-right:20px;
				}
			}

			a {
				color:#888;
				font-family:'IBM Plex Sans';
				font-size:1.2vw;
				line-height:1.2vw;
				text-decoration: none;
				
				&:hover {
					border-bottom:1px solid #888;
				}
				
				&.active {
					border-bottom:1px solid #888;
				}

			}

		}

	}
	
}