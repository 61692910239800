/* 
Desktopscreens higher then 1921px
*/
$screen-xl-1921: 1921px;

/* 
Desktopscreens
*/
$screen-xl-max2: 1700px;
/* 

Large tablets and desktops
*/
$screen-xl-max: 1200px;

/* 
Tablets and small desktops
*/
$screen-lg-max: 992px;

/* 
Small tablets (portrait view)
*/
$screen-md-max: 768px;

/* 
Small tablets and large smartphones (landscape view)
*/
$screen-sm-max: 576px;

/* 
Extra large devices higher then 1921
*/
@mixin xl3 {
   @media (min-width: #{$screen-xl-1921}) {
       @content;
   }
}


/* 
Extra large devices
*/
@mixin xl2 {
   @media (max-width: #{$screen-xl-max2}) {
       @content;
   }
}

/* 
Extra large devices
*/
@mixin xl {
   @media (max-width: #{$screen-xl-max}) {
       @content;
   }
}

/* 
Large devices
*/
@mixin lg {
   @media (max-width: #{$screen-lg-max}) {
       @content;
   }
}

/*
Medium devices
*/
@mixin md {
   @media (max-width: #{$screen-md-max}) {
       @content;
   }
}

/* 
Small devices
*/
@mixin sm {
   @media (max-width: #{$screen-sm-max}) {
       @content;
   }
}