@import "_default.scss_include-mixins";

.textblock-1 {

	float:left;
	width:100%;

	p,ul li,.p {
		padding:0;
		margin:0;
		font-size:2.5vw;
		line-height:4vw;
		color:#000;

		@include sm {
			font-size:14px;
			line-height:20px;
		}

		&.small {
			font-size:20px;

			@include sm {
				font-size:14px;
				line-height:20px;
			}
			
		}

		a {
			color:#888;

			&:hover {
				color:#000;
			}
			
		}

	}

}