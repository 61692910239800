@import "_default.scss_include-mixins";

body {
	font-family:'IBM Plex Sans', serif;
	background-color:#fff;
	overflow-x:hidden;
}

/*
Selection
*/
::selection {
	background:#000;
	color:#fff;
}