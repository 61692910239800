.cta {
	
	float:left;
	width:100%;
	margin-top:3vw;

	h2 {
		font-family:'IBM Plex Sans';
	}

	.row-buttons {
		margin-top:2vw;
	}

	.button {
		margin-left:1.2vw;
		margin-right:1.2vw;
	}

}