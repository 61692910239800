@import "_default.scss_include-mixins";

.button {
	display:inline-block;
	padding:15px;
	padding-left:4vw;
	padding-right:4vw;
	font-size:1.5vw;
	color:#fff;
	border-radius:50px;
	background-color:#000;
	border:1px solid #707070;
	text-decoration: none;

	@include sm {
		font-size:14px;
	}

	&:hover {
		background-color:#fff;
		color:#000;
	}

}